import React from 'react';
import { useState, useEffect } from "react";
import profileIcon from "../../assets/Assets/Svgs/profileIcon.svg";
import SignupFormFour from "./SignupFormFour";
import { useSignUpContext } from "./SignUpContext";

const SignupFormThree = ({ setProgress, setStep }) => {
  // const [nextModal, setNextModal] = useState(false);
  useEffect(() => {
    setProgress(4);
  }, [setProgress]);

  const { setSignupData, signupData } = useSignUpContext();

  const [option, setOption] = useState(signupData.field);
  const [filled, setFilled] = useState(true);


  const handleClick = () => {
    if (option) {
      setSignupData((prevData) => ({
        ...prevData,
        field: option,
      }));
      setStep(5);
    } else {
      setFilled(false);
    }
  };

  return (
    <div>
      <div
        style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
        className="flex flex-col shadow-lg items-center h-[100%] mb-[5%] lg:mb-0 pb-8 bg-white rounded-[20px] mt-3 z-1  max-[767px]:mt-[15%] max-[767px]:w-[100%] tablet:h-fit laptop:h-[70vh] laptop:max-h-[45rem] laptop:min-h-[610px]  tablet:w-[60vw] mx-auto tablet:min-w-[520px] tablet:max-w-[522px] lg:overflow-y-scroll lg:overscroll-none"
      >
        <div className="pt-[8%] laptop:pt-6 text-center  flex flex-col items-center border-b-[1px] border-[#E2E4E9] w-[90%] pb-[5%]">
          <img alt="profile" src={profileIcon} />
          <h1 className="text-[24px]  font-bold max-[767px]:text-[20px]">
            Select the best option that describes you
          </h1>
          <p>Select an option to proceed.</p>
        </div>

        <div className=" w-full">
          <p
            className={` text-red-700 w-full ${
              filled ? "opacity-0" : "opacity-1"
            } tracking-wider text-center my-2`}
          >
            Please select an option!
          </p>
          <div className="flex flex-col justify-center items-center gap-4 mb-6 w-full">
            <button
              value="Clinical Psychologist"
              onClick={(e) => setOption(e.target.value)}
              className={`w-[90%] ${
                option === "Clinical Psychologist"
                  ? "bg-[#1A6A73] text-white "
                  : ""
              }  ease-in-out    text-center border-[2px] py-[3%] border-[#F9F7F3] rounded-[30px]`}
            >
              Clinical Psychologist
            </button>
            <button
              value="Mental Health Coach / Psychologist"
              onClick={(e) => setOption(e.target.value)}
              className={`w-[90%] ${
                option === "Mental Health Coach / Psychologist"
                  ? "bg-[#1A6A73] text-white "
                  : ""
              }  ease-in-out    text-center border-[2px] py-[3%] border-[#F9F7F3] rounded-[30px]`}
            >
              Mental Health Coach / Psychologist
            </button>
            <button
              value="Mental Health Counselor"
              onClick={(e) => setOption(e.target.value)}
              className={`w-[90%] ${
                option === "Mental Health Counselor"
                  ? "bg-[#1A6A73] text-white "
                  : ""
              }  ease-in-out    text-center border-[2px]  py-[3%] border-[#F9F7F3] rounded-[30px]`}
            >
              Mental Health Counselor
            </button>
          </div>
          <div>
            <button
              onClick={handleClick}
              className="bg-[#1A6A73] text-center w-[90%] mx-[5%] py-[4%]  text-[white] rounded-[30px]"
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupFormThree;
