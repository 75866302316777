
const conditions = [
    "Cognitive Behavioral Therapy (CBT)",
    "Mindfulness-Based Therapies",
    "Emotionally Focused Therapy (EFT)",
    "Solution-Focused Therapy",
    "Trauma-FocusedTherapy",
    "Acceptance and Commitment Therapy (ACT)",
    "Motivational Interviewing",
    "Dialectical Behavior Therapy (DBT)",
    "Psychodynamic Therapy",
    "Client-Centered Therapy",
    "Family Therapy",
];

const mostSpokenLanguages = [
    "English",
    "Mandarin Chinese",
    "Hindi",
    "Spanish",
    "French",
    "Arabic",
    "Bengali",
    "Portuguese",
    "Russian",
    "Urdu",
    "Indonesian",
    "German",
    "Japanese",
    "Swahili",
    "Marathi",
    "Telugu",
    "Turkish",
    "Korean",
    "Tamil",
    "Vietnamese",
    "Punjabi",
    "Italian",
    "Thai",
    "Gujarati",
    "Polish",
    "Ukrainian",
    "Persian (Farsi)"
];



const conditions_treat = [
    'Addiction Support',
    'Anger Management',
    'Anxiety Disorders',
    'Bipolar Disorder',
    'Career Counseling',
    'Chronic Impulsivity',
    'Conflict Resolution',
    'Depression',
    'Eating Disorders',
    'Family Dynamics',
    'Grief Counseling',
    'Health and Wellness',
    'Intimacy Issues',
    'Life Transitions',
    'Parenting Support',
    'Relationship Guidance',
    'Religious Counseling',
    'Self-Esteem Building',
    'Sleep Disorders',
    'Social Behavior Challenges',
    'Stress Management',
    'Substance Abuse Recovery',
    'Trauma and Abuse Healing',
];


export {
    conditions,
    conditions_treat,
    mostSpokenLanguages
}
