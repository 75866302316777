import React from 'react'
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import About from "./Pages/About";
import Faq from "./Pages/Faq";
import Contact from "./Pages/Contact";
import ForTherapist from "./Pages/ForTherapist";
import HomePage from "./Pages/HomePage";
import Login from "./Pages/Login";
import MentraForWork from "./Pages/MentraForWork";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import Signup from "./Pages/Signup";
import TermsAndConditions from "./Pages/TermsAndConditions";
import WellnessIndex from "./Pages/WellnessLibary/WellnessIndex";
import ScrollToTop from "./ScrollToTop";
import Header from "./Components/Header";


import WellnessDetail from "./Pages/WellnessLibary/WellnessDetail";
import Wrapper from "./App/Wrapper";
import Dashboard from "./App/pages/Dashboard";
import Settings from "./App/pages/Settings";
import Personal from "./App/components/AccountSettings/Personal";
import Professional from "./App/components/AccountSettings/Professional";
import Notification from "./App/components/AccountSettings/Notification";
import Security from "./App/components/AccountSettings/Security";
import Delete from "./App/components/AccountSettings/Delete";
import { Toaster } from "react-hot-toast";
import HowToDoMore from "./App/pages/HowToDoMore";
import ClientList from "./App/pages/ClientList";
import ClientProfile from "./App/pages/ClientProfile";
import Appointments from "./App/pages/Appointments";
import Availability from "./App/pages/Availability";
import AvailHours from "./App/components/Availability/AvailHours";
import Blocked from "./App/components/Availability/Blocked";
import Resources from "./App/pages/Resources";
import Protected from "./App/wrappers/protected";
import VideoChat from "./App/pages/VideoChat";
import ForgotPasscode from "./Pages/ForgotPasscode"
import Messages from "./App/pages/Messages";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import WorksheetDetails from "./App/pages/worksheetDetails";
import ClientWorksheetDetails from "./App/pages/clientworksheetdetails";
import ArticleDetails from "./App/pages/articleDetails";
import { WaitingRoom } from "./App/pages/WaitingRoom";

function App() {
  // const location = useLocation();

  return (
    <Router>
      <ToastContainer />
      <Header />
      <ScrollToTop />
      <Toaster />
      <Routes>
        <Route element={<HomePage />} path="/" />
        <Route element={<Faq />} path="/Faq" />
        <Route element={<About />} path="/about" />
        <Route element={<Contact />} path="/contact" />
        <Route element={<ForTherapist />} path="/for-therapist" />
        <Route element={<Login />} path="/login" />
        <Route element={<ForgotPasscode/>} path="/forgotPasscode"/>
        <Route element={<MentraForWork />} path="/mentra-for-work" />
        <Route element={<PrivacyPolicy />} path="/privacy-policy" />
        <Route element={<Signup />} path="/signup" />
        <Route element={<TermsAndConditions />} path="/terms-and-conditions" />
        <Route element={<WellnessIndex />} path="/wellness-library" />
        <Route
          element={<WellnessDetail />}
          path="/wellness-library/:id/:slug?"
        />

        {/* Protected Routes go in here */}
        <Route path="video-chat/:id" element={<VideoChat />} />
        <Route element = { <Protected /> }>
          {/*<Route path="video-chat/:id" element={<VideoChat />} />*/}
          {/* <Route path="start-call" element={<WaitingRoom />} /> */}
          <Route path="/dashboard/*" element={<Wrapper />}>
            <Route path="home" element={<Dashboard />} />
            <Route path="do_more" element={<HowToDoMore />} />
            <Route path="client_list" element={<ClientList />} />
            <Route path="appointments" element={<Appointments />} />
            <Route path="messages" element={<Messages />} />
            <Route path="resources" element={<Resources />} />
            <Route path="resources/:id" element={<WorksheetDetails />} />
            <Route path="resources/article/:slug" element={<ArticleDetails />} />
            <Route path="availability/*" element={<Availability />}>
              <Route path="availability_hours" element={<AvailHours />} />
              <Route path="blocked_dates" element={<Blocked />} />
            </Route>
            <Route path="client_list/:id" element={<ClientProfile />} />
            <Route path="client_list/:id/worksheet" element={<ClientWorksheetDetails />} />
            <Route path="settings/*" element={<Settings />}>
              <Route path="personal_info" element={<Personal />} />
              {/*TODO CLEAN ALL THAT HAS TO DO WITH Professional*/}
              <Route path="professional_info" element={<Professional />} />
              <Route path="notification_settings" element={<Notification />} />
              <Route path="security_settings" element={<Security />} />
              <Route path="delete_account" element={<Delete />} />
            </Route>
          </Route>
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
