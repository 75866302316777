import React from "react";
import png from "../../Images/png-icon.png";
import pdf from "../../Images/pdf-icon.png";
import jpg from "../../Images/jpg-icon.png";
import doc from "../../Images/doc-icon.png";
import docx from "../../Images/docx-icon.png";
import { Skeleton } from "@/Components/ui/skeleton";
import chevron from "@/App/Images/chevron-right.png";

const Credentials = ({ documentIsLoading, usersDocuments, handleUpdate }) => {
    return (
        <div className="w-full laptop:w-[48%] justify-start">
            {documentIsLoading ? (
                <div className="flex w-full h-fit gap-y-3 gap-x-[3%] tablet:gap-y-[2.5vw] nine:gap-y-[1.5vw] laptop:gap-3 flex-wrap items-end tablet:items-start">
                    <Skeleton className="w-full tablet:w-[48.5%] h-[75px] laptop:w-[190px] rounded-[12px]" />
                    <Skeleton className="w-full tablet:w-[48.5%] h-[75px] laptop:w-[190px] rounded-[12px]" />
                </div>
            ) : (
                <div className="flex w-full h-fit gap-y-3 gap-x-[3%] tablet:gap-y-[2.5vw] nine:gap-y-[1.5vw] laptop:gap-3 flex-wrap items-start">
                    {usersDocuments?.data.length > 0 &&
                        usersDocuments.data.map((item, index) => (
                            <div
                                key={index}
                                className="flex flex-col w-full tablet:w-[48.5%] laptop:w-[190px] gap-2"
                            >
                                {/* Document Container */}
                                <div className="p-4 rounded-[12px] border border-[#E2E4E9] flex justify-between items-center">
                                    <div className="flex items-center gap-3">
                                        <img
                                            src={
                                                item.file.name.includes(".png")
                                                    ? png
                                                    : item.file.name.includes(".pdf")
                                                        ? pdf
                                                        : item.file.name.includes(".doc")
                                                            ? doc
                                                            : item.file.name.includes(".docx")
                                                                ? docx
                                                                : jpg
                                            }
                                            alt=""
                                            className="w-[40px]"
                                        />
                                        <div className="space-y-[2px]  overflow-hidden w-[100px]">
                                            <p className="font-medium text-[14px] truncate">
                                                {item.file.name}
                                            </p>
                                            <p className="font-normal text-[12px] text-[#333333]">
                                                {item.file.size / 1024 > 1024
                                                    ? `${(item.file.size / (1024 * 1024)).toFixed(2)} MB`
                                                    : `${Math.ceil(item.file.size / 1024)} KB`}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                {/* View Document Button */}
                                <button
                                    className="py-1 bg-[#1A6A73] text-white text-[12px] font-medium rounded-md hover:bg-[#15585d] transition"
                                    onClick={() => window.open(item.file.url, "_blank")}
                                >
                                    View Document
                                </button>
                            </div>
                        ))}
                </div>
            )}

            <div className="flex cursor-pointer justify-start items-center gap-[2px] pt-1" onClick={handleUpdate}>
                <p className="text-[14px] leading-5 font-medium text-[#1A6A73]">
                    Edit
                </p>
                <img src={chevron} className="w-5" alt="edit" />
            </div>
        </div>
    );
};

export default Credentials;



// import React from "react";
// import png from "../../Images/png-icon.png";
// import pdf from "../../Images/pdf-icon.png";
// import jpg from "../../Images/jpg-icon.png";
// import doc from "../../Images/doc-icon.png";
// import docx from "../../Images/docx-icon.png";
// import {Skeleton} from "@/Components/ui/skeleton";
// import chevron from "@/App/Images/chevron-right.png";
//
// const Credentials = ({documentIsLoading, usersDocuments, handleUpdate}) => {
//     return (
//         <div className=" w-full laptop:w-[48%] justify-start">
//             {documentIsLoading ? (
//                 <div className="flex w-full  h-fit  gap-y-3 gap-x-[3%] tablet:gap-y-[2.5vw] nine:gap-y-[1.5vw] laptop:gap-3 flex-wrap items-end  tablet:items-start">
//                     <Skeleton className=" w-full tablet:w-[48.5%] h-[75px] laptop:w-[190px] rounded-[12px]"/>
//                     <Skeleton className=" w-full tablet:w-[48.5%] h-[75px] laptop:w-[190px] rounded-[12px]"/>
//                 </div>
//             ) : (
//                 <div className="flex w-full  h-fit  gap-y-3 gap-x-[3%] tablet:gap-y-[2.5vw] nine:gap-y-[1.5vw] laptop:gap-3 flex-wrap items-end  tablet:items-start">
//                     {usersDocuments?.data.length > 0 &&
//                         usersDocuments.data.map((item, index) => (
//                             <div
//                                 key={index}
//                                 className=" w-full tablet:w-[48.5%] laptop:w-[190px] py-4 pl-4 pr-0 rounded-[12px] border border-[#E2E4E9] flex justify-between items-center"
//                             >
//                                 <div className="flex justify-start items-center max-w-[90%] gap-3">
//                                     <img
//                                         src={
//                                             item.file.name.includes(".png")
//                                                 ? png
//                                                 : item.file.name.includes(".pdf")
//                                                     ? pdf
//                                                     : item.file.name.includes(".doc")
//                                                         ? doc
//                                                         : item.file.name.includes(".docx")
//                                                             ? docx
//                                                             : jpg
//                                         }
//                                         alt=""
//                                         className="w-[40px]"
//                                     />
//                                     <div className="space-y-[2px] *:text-nowrap overflow-hidden">
//                                         <p className="font-medium text-[14px]">
//                                             {item.file.name}
//                                         </p>
//                                         <p className="font-normal text-[12px] text-[#333333]">
//                                             {item.file.size / 1024 > 1024
//                                                 ? `${(item.file.size / (1024 * 1024)).toFixed(2)} MB`
//                                                 : `${Math.ceil(item.file.size / 1024)} KB`}
//                                         </p>
//                                     </div>
//                                 </div>
//                             </div>
//
//                         ))}
//                 </div>
//             )}
//
//             <div className="flex cursor-pointer justify-start items-center gap-[2px] pt-1" onClick={handleUpdate}>
//                 <p className="text-[14px] leading-5 font-medium text-[#1A6A73]">
//                     Edit
//                 </p>
//                 <img src={chevron} className="w-5" alt="edit"/>
//             </div>
//         </div>
//     );
// };
//
// export default Credentials;
