import React from 'react';
import { useState } from "react";
import lock from "../../Images/pass-lock.png";
import pass from "../../Images/pass.png";
import fail from "../../Images/fail.png";
import eyeclose from "../../Images/eyeclose.png";
import eyeopen from "../../Images/eyeopen.png";
import toast from "react-hot-toast";

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/Components/ui/dialog";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/Components/ui/table";

import { Label } from "@/Components/ui/label";
import usePINValidator from "../hooks/usePassCode";
import { useUpdatePasswordMutation } from "@/App/services/settingsApiSlice";
import { FormLoader } from "@/Components/loader";

function Security() {
  const [showCurrent, setShowCurrent] = useState(false);
  function handleShow() {
    setShowCurrent(!showCurrent);
  }
  const [showNew, setShowNew] = useState(false);
  function handleNew() {
    setShowNew(!showNew);
  }
  const [showConfirm, setShowConfirm] = useState(false);
  function handleConfirm() {
    setShowConfirm(!showConfirm);
  }

  // function hasNumber(str) {
  //   return /[0-9]/.test(str);
  // }

  // function hasUpperCase(str) {
  //   return /[A-Z]/.test(str);
  // }

  // const [upper, setUpper] = useState(false);
  // const [nums, setNums] = useState(false);
  // const [eight, setEight] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassWord, setNewPassWord] = useState("");
  const [confirmNewPassWord, setConfirmNewPassWord] = useState("");

  const { validatePIN: validatePINcurrent } = usePINValidator();
  const { validatePIN: validatePINnew } = usePINValidator();
  const { validatePIN: validatePINconfirm } = usePINValidator();

  const [updatePassword, { isLoading }] = useUpdatePasswordMutation();

  async function submitHandler(event) {
    event.preventDefault();
    try {
      const isValidCurrent = validatePINcurrent(+currentPassword);
      const isValidNew = validatePINnew(+newPassWord);
      const isValidConfirm = validatePINconfirm(+confirmNewPassWord);

      console.log(isValidConfirm, isValidCurrent, isValidNew);

      if (isValidCurrent && isValidNew && isValidConfirm) {
        if (newPassWord !== confirmNewPassWord) {
          toast.error("Confirm new password correctly.");
        } else {
          const newPassCode = await updatePassword({
            current_password: currentPassword,
            password: newPassWord,
            password_confirmation: confirmNewPassWord,
          }).unwrap();
          console.log(newPassCode);
          toast.success("Password updated successfully");
        }
      } else {
        toast.error("Passcodes must be 4-digit PINs");
      }
    } catch (error) {
      toast.error("Error updating password");
    } finally {
      setCurrentPassword("");
      setNewPassWord("");
      setConfirmNewPassWord("");
      setOpenModal(false);
    }
  }

  // function handleChange() {

  // if (hasUpperCase(value)) {
  //   setUpper(true);
  // } else {
  //   setUpper(false);
  // }

  // if (hasNumber(value)) {
  //   setNums(true);
  // } else {
  //   setNums(false);
  // }

  // if (value?.length >= 8) {
  //   setEight(true);
  // } else {
  //   setEight(false);
  // }
  // }

  const invoices = [
    {
      browser: "google chrome",
      location: "Québec, Canada",
      activity: "current session",
      ip: "224.0.1.1",
    },
    {
      browser: "brave",
      location: "Vancouver, Canada",
      activity: "Sep 26, 2023 at 10:00 AM",
      ip: "224.0.1.1",
    },
    {
      browser: "mozilla firefox",
      location: "Toronto, Canada",
      activity: "Sep 26, 2023 at 10:00 AM",
      ip: "224.0.1.1",
    },
    {
      browser: "opera ",
      location: "Québec, Canada",
      activity: "Sep 26, 2023 at 10:00 AM",
      ip: "224.0.1.1",
    },
    {
      browser: "google chrome",
      location: "Vancouver, Canada",
      activity: "Sep 26, 2023 at 10:00 AM",
      ip: "224.0.1.1",
    },
  ];
  return (
    <div>
      <div>
        <h2 className="font-meduim text-[16px] tablet:text-[18px] leading-6 text-[#0A0D14]">
          Security
        </h2>
        <p className="text-[#525866] text-[13px] leading-[19px] tablet:text-[14px] tablet:leading-5 pt-[2px] tablet:pt-1">
          Personalize your privacy settings and enhance the security of your
          account.
        </p>
      </div>
      <hr className="w-full border-none h-[1px] bg-[#E2E4E9] my-5" />
      <div className="w-full flex justify-center items-start gap-[4%]">
        <div className="flex w-[48%] h-fit justify-start flex-col items-start">
          <h3 className="text-[#0A0D14] text-[14px] tablet:text-[15px] leading-5 font-medium">
            Change Password
          </h3>
          <p className="text-[12px] tablet:text-[13px] leading-4 text-[#525866] pt-1">
            Update password for enhanced account security.
          </p>
        </div>
        <div className="flex w-[48%] h-fit justify-end  items-end flex-col ">
          <Dialog open={openModal} onOpenChange={setOpenModal}>
            <DialogTrigger className="border-[#E2E4E9] rounded-[50px] cursor-pointer border text-[13px] tablet:text-[14px] font-meduim leading-5 text-[#525866] py-2 tablet:py-[10px] px-3 tablet:px-4">
              Change Password
            </DialogTrigger>
            <DialogContent
              style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
              className="w-[90vw] max-h-[80vh] overflow-y-scroll max-w-[566px] duo:p-8 rounded-[16px]"
            >
              <DialogHeader>
                <DialogTitle className="text-left text-[#0A0D14]">
                  Change Password
                </DialogTitle>
                <DialogDescription className="text-left text-[#525866] ">
                  Update password for enhanced account security.
                </DialogDescription>
              </DialogHeader>
              <hr className="w-full border-none h-[1px] bg-[#E2E4E9] mt-0 mb-4" />

              <form onSubmit={submitHandler}>
                <div className="space-y-2">
                  <div>
                    <Label
                      htmlFor="name"
                      className="text-left text-[14px] capitalize text-[#0A0D14] leading-5 font-semibold"
                    >
                      Current Password*
                    </Label>

                    <div
                      tabIndex="0"
                      className="border-2 border-[#E2E4E9] relative overflow-hidden  rounded-[10px] mt-1 w-full"
                    >
                      <img
                        className=" w-[20px] absolute top-1/2 left-3 transform -translate-y-1/2"
                        src={lock}
                        alt="show password"
                      />

                      <input
                        style={{
                          outline: "none",
                          border: "none",
                          boxShadow: "none",
                        }}
                        id="name"
                        type={showCurrent ? "text" : "password"}
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        required
                        placeholder="********"
                        className="w-full   font-medium text-[14px] pl-[42px] py-[6px] focus:outline-red-500!  "
                      />
                      <button
                        type="button"
                        onClick={handleShow}
                        className="absolute top-1/2 right-3 transform -translate-y-1/2 "
                      >
                        <img
                          className=" w-[19px]"
                          src={showCurrent ? eyeopen : eyeclose}
                          alt="show password"
                        />
                      </button>
                    </div>
                  </div>
                  <div>
                    <Label
                      htmlFor="name"
                      className="text-left text-[14px] capitalize text-[#0A0D14] leading-5 font-semibold"
                    >
                      New password*
                    </Label>

                    <div
                      tabIndex="0"
                      className="border-2 border-[#E2E4E9] relative overflow-hidden  rounded-[10px] mt-1 w-full"
                    >
                      <img
                        className=" w-[20px] absolute top-1/2 left-3 transform -translate-y-1/2"
                        src={lock}
                        alt="show password"
                      />
                      <input
                        style={{
                          outline: "none",
                          border: "none",
                          boxShadow: "none",
                        }}
                        id="name"
                        type={showNew ? "text" : "password"}
                        required
                        placeholder="********"
                        value={newPassWord}
                        onChange={(e) => setNewPassWord(e.target.value)}
                        className="w-full   font-medium text-[14px] pl-[42px] py-[6px] focus:outline-red-500!  "
                      />
                      <button
                        type="button"
                        onClick={handleNew}
                        className="absolute top-1/2 right-3 transform -translate-y-1/2 "
                      >
                        <img
                          className=" w-[19px]"
                          src={showNew ? eyeopen : eyeclose}
                          alt="show password"
                        />
                      </button>
                    </div>
                  </div>
                  <div>
                    <Label
                      htmlFor="name"
                      className="text-left text-[14px] capitalize text-[#0A0D14] leading-5 font-semibold"
                    >
                      Confirm New Password*
                    </Label>

                    <div
                      tabIndex="0"
                      className="border-2 border-[#E2E4E9] relative overflow-hidden  rounded-[10px] mt-1 w-full"
                    >
                      <img
                        className=" w-[20px] absolute top-1/2 left-3 transform -translate-y-1/2"
                        src={lock}
                        alt="show password"
                      />
                      <input
                        style={{
                          outline: "none",
                          border: "none",
                          boxShadow: "none",
                        }}
                        id="name"
                        type={showConfirm ? "text" : "password"}
                        required
                        placeholder="********"
                        value={confirmNewPassWord}
                        onChange={(e) => setConfirmNewPassWord(e.target.value)}
                        className="w-full   font-medium text-[14px] pl-[42px] py-[6px] focus:outline-red-500!  "
                      />
                      <button
                        type="button"
                        onClick={handleConfirm}
                        className="absolute top-1/2 right-3 transform -translate-y-1/2 "
                      >
                        <img
                          className=" w-[19px]"
                          src={showConfirm ? eyeopen : eyeclose}
                          alt="show password"
                        />
                      </button>
                    </div>
                  </div>
                </div>

                {/* <div className="flex justify-between items-center mt-4 *:w-[32%] *:overflow-hidden *:bg-[#E2E4E9] *:h-1 *:rounded-[1.2px] *:*:bg-[#DF1C41] *:*:origin-left *:*:transition-all *:*:duration-500 *:*:ease-in-out">
                  <div>
                    <div className={`${upper ? "size-full" : "w-0"} `}></div>
                  </div>
                  <div>
                    <div className={`${nums ? "size-full" : "w-0"} `}></div>
                  </div>
                  <div>
                    <div className={`${eight ? "size-full" : "w-0"} `}></div>
                  </div>
                </div> */}

                {/* <div className="mt-3">
                  <h2 className="text-[#525866] text-[12px] tablet:text-[13px] font-medium leading-5">
                    {upper && nums && eight
                      ? "Strong password. Contains;"
                      : "Weak password. Must contain at least;"}
                  </h2>
                  <div className="space-y-2 mt-2 *:text-[#525866] *:text-[12px] *:tablet:text-[13px] *:font-medium *:leading-5 *:flex *:gap-1 *:items-center *:justify-start">
                    <div>
                      <img
                        className="w-4"
                        src={upper ? pass : fail}
                        alt="validate"
                      />
                      <p>At least 1 uppercase</p>
                    </div>
                    <div>
                      <img
                        className="w-4"
                        src={nums ? pass : fail}
                        alt="validate"
                      />
                      <p>At least 1 number</p>
                    </div>
                    <div>
                      <img
                        className="w-4"
                        src={eight ? pass : fail}
                        alt="validate"
                      />
                      <p>At least 8 characters</p>
                    </div>
                  </div>
                </div> */}
                <hr className="w-full border-none h-[1px] bg-[#E2E4E9] mb-6 mt-8" />
                <div className="flex items-end justify-end">
                  <button
                    type="submit"
                    className="bg-[#1A6A73] w-[162px] flex justify-center items-center rounded-[50px] text-white text-[14px] leading-[14px] font-meduim   h-[43px] "
                  >
                    {isLoading ? <FormLoader /> : " Change Password"}
                  </button>
                </div>
              </form>
            </DialogContent>
          </Dialog>
        </div>
      </div>
      {/* <hr className="w-full border-none h-[1px] bg-[#E2E4E9] my-5" />
      <div className="w-full flex justify-between tablet:justify-center items-start tablet:gap-[4%]">
        <div className="flex w-[48%] h-fit justify-start flex-col items-start">
          <h3 className="text-[#0A0D14] text-[14px] tablet:text-[15px] leading-5 font-medium">
            Active Sessions
          </h3>
          <p className="text-[12px] tablet:text-[13px] leading-4 text-[#525866] pt-1">
            Monitor and manage all your active sessions.
          </p>
        </div>
        <div className="flex w-1/2 tablet:w-[48%] h-fit justify-end  items-end flex-col">
          <button className="border-[#DF1C41] rounded-[50px] cursor-pointer border text-[13px] tablet:text-[14px] font-meduim leading-5 text-[#DF1C41] py-2 tablet:py-[10px] px-3 tablet:px-4">
            Log Out All Sessions
          </button>
        </div>
      </div>
      <hr className="w-full border-none h-[1px] bg-[#E2E4E9] my-5" />
      <div className="w-full">
        <div
          style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
          className="overflow-x-scroll w-full"
        >
          <div className="mt-6 min-w-[720px]">
            <Table>
              <TableHeader className="bg-[#F6F8FA] hover:bg-[#F6F8FA]">
                <TableRow className="*:text-[#525866]">
                  <TableHead className="w-[25%]">Browser</TableHead>
                  <TableHead>Location</TableHead>
                  <TableHead>Recent activity</TableHead>
                  <TableHead className="text-left">IP Address</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {invoices.map((invoice) => (
                  <TableRow className="*:min-w-[150px]" key={invoice.ip}>
                    <TableCell className="font-medium text-[#0A0D14] capitalize">
                      {invoice.browser}
                    </TableCell>
                    <TableCell className="text-[#525866] capitalize">
                      {invoice.location}
                    </TableCell>
                    <TableCell className="capitalize text-[#525866]">
                      {invoice.activity}
                    </TableCell>
                    <TableCell className="text-left text-[#525866]">
                      {invoice.ip}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default Security;

{
  /* The tremor ui libary is forcing a border on every input thats ehy i have to set the borders using inline styles and add !important to the tailwind styles*/
}
