import React from 'react';
import loaderimg from "../assets/Assets/Images/loader.gif";
import {
  BounceLoader,
  GridLoader,
  PropagateLoader,
  SyncLoader,
} from "react-spinners";

function Loader() {
  return (
    <>
      <div className="w-full grid place-items-center">
        <GridLoader color="#1A6A73" />
      </div>
      {/* <div>
        <img src={loaderimg} alt="loader" />
      </div> */}
    </>
  );
}
function FormLoader() {
  return (
    <>
      <div className="w-full">
        <SyncLoader color="#2fa5b3" />
      </div>
    </>
  );
}
function ContactLoader() {
  return (
    <>
      <div className="w-full  ">
        <SyncLoader color="#2fa5b3" size={13} />
      </div>
    </>
  );
}

export { FormLoader, ContactLoader };

export default Loader;
