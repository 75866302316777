import React from "react";
import { useState, useEffect, createContext, useContext } from "react";
import ReactGA from "react-ga4";
import active from "../Images/active_setting.png";
import deleteIcon from "../Images/delete.png";
import notification from "../Images/notification.png";
import personal from "../Images/personal.png";
import security from "../Images/security.png";
import professional from "../Images/professional.png";
import { NavLink, useNavigate, Outlet } from "react-router-dom";
import toast from "react-hot-toast";

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/Components/ui/select";
import { useSettingsDataQuery } from "../services/settingsApiSlice";
import Loader from "@/Components/loader";
import { handleError } from "../utils/renderErrors";

const SettingsDataContext = createContext();

export function useSettingsDataContext() {
  return useContext(SettingsDataContext);
}

function Settings() {
  const navigate = useNavigate();
  const [select, setSelect] = useState("");
  const [settingsData, setSettingsData] = useState("");

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });

    const path = window.location.pathname;
    const value = path.split("/");
    setSelect(value[value.length - 1]);
  }, []);

  const { data: profileData, error: profileError, refetch } = useSettingsDataQuery();
  

  useEffect(() => {
    try {
      if (profileError) {
        toast.error(
          "An error ocurred please check your internet connection and try again"
        );
      }
      if (!profileError && profileData.data !== settingsData) {
        setSettingsData(profileData.data);
        // console.log(profileData.data);
      }
    } catch (error) {
      const errorMessage = handleError(error);
      toast.error(errorMessage);
    }
  }, [profileData, profileError, settingsData]);

  // const navigate = useNavigate();
  function handleChange(value) {
    setSelect(value);
    navigate(value);
  }
  return (
    <div>
      <div>
        <h2 className="text-[#101828] text-left text-[1.4rem] leading-[30px] tablet:text-[1.9rem] tablet:leading-[38px] font-semibold">
          Account Settings
        </h2>
        <p className="font-normal text-[14px] leading-[22px] tablet:text-[1rem] text-left tablet:leading-6 max-w-[450px] laptop:max-w-full text-[#475467] pt-[3px] tablet:pt-[6px]">
          Customize your account settings and manage your therapist profile
          here.
        </p>
      </div>
      <hr className="w-full my-7 border-none h-[1px] bg-[#EAECF0]" />
      <Select
        onValueChange={(value) => handleChange(value)}
        defaultValue={select}
        value={select}
      >
        <SelectTrigger className=" mb-3 px-[10px] nine:hidden w-[230px] focus:ring-offset-0 focus:ring-0 h-[45px]  font-semibold text-[#525866] ">
          <SelectValue />
        </SelectTrigger>
        <SelectContent className=" ">
          <SelectGroup className=" *:font-medium *:py-3 ">
            <SelectItem value="personal_info">
              <div className="flex justify-between w-full group ">
                <div className="flex justify-start items-center gap-2 ">
                  <img src={personal} alt="personal" className="w-5" />
                  <p className="text-[#525866] text-[14px] leading-5 capitalize  font-medium">
                    personal info
                  </p>
                </div>
              </div>
            </SelectItem>

            <SelectItem value="professional_info">
              <div className="flex justify-between w-full group ">
                <div className="flex justify-start items-center gap-2 ">
                  <img src={professional} alt="personal" className="w-5" />
                  <p className="text-[#525866] text-[14px] leading-5 capitalize  font-medium">
                    professional info
                  </p>
                </div>
              </div>
            </SelectItem>
            <SelectItem value="security_settings">
              <div className="flex justify-between w-full group ">
                <div className="flex justify-start items-center gap-2 ">
                  <img src={security} alt="personal" className="w-5" />
                  <p className="text-[#525866] text-[14px] leading-5 capitalize  font-medium">
                    Security
                  </p>
                </div>
              </div>
            </SelectItem>
            <SelectItem value="notification_settings">
              <div className="flex justify-between w-full group ">
                <div className="flex justify-start items-center gap-2 ">
                  <img src={notification} alt="personal" className="w-5" />
                  <p className="text-[#525866] text-[14px] leading-5 capitalize  font-medium">
                    notification settings
                  </p>
                </div>
              </div>
            </SelectItem>
            <SelectItem value="delete_account">
              <div className="flex justify-between w-full group ">
                <div className="flex justify-start items-center gap-2 ">
                  <img src={deleteIcon} alt="personal" className="w-5" />
                  <p className="text-[#525866] text-[14px] leading-5 capitalize  font-medium">
                    Delete Account
                  </p>
                </div>
              </div>
            </SelectItem>
          </SelectGroup>
        </SelectContent>
      </Select>
      <div className="w-full flex justify-center items-start gap-[3%] ">
        <div className="w-[30%] laptop:w-[22%] bg-white px-[10px] pt-[10px] pb-3 rounded-[16px] border hidden nine:flex flex-col justify-start items-start border-[#E2E4E9]">
          <h2 className="text-[#868C98] px-2 pt-2 font-medium text-[12px] leading-4 uppercase">
            select menu
          </h2>
          <div className="mt-3 w-full space-y-2">
            <NavLink
              onClick={() => setSelect("personal_info")}
              to="personal_info"
              className={({ isActive }) =>
                `flex justify-between w-full rounded-[8px] group p-2 ${
                  isActive ? "is-active bg-[#F6F8FA]" : ""
                }`
              }
            >
              <div className="flex justify-start items-center gap-2 ">
                <img src={personal} alt="personal" className="w-5" />
                <p className="text-[#525866] text-[14px] leading-5 capitalize group-[.is-active]:text-[#0A0D14] font-medium">
                  personal info
                </p>
              </div>
              <img
                src={active}
                alt="chevron"
                className="w-5 hidden group-[.is-active]:block"
              />
            </NavLink>
            {/*<NavLink*/}
            {/*  onClick={() => setSelect("professional_info")}*/}
            {/*  to="professional_info"*/}
            {/*  className={({ isActive }) =>*/}
            {/*    `flex justify-between w-full rounded-[8px] group p-2 ${*/}
            {/*      isActive ? "is-active bg-[#F6F8FA]" : ""*/}
            {/*    }`*/}
            {/*  }*/}
            {/*>*/}
            {/*  <div className="flex justify-start items-center gap-2 ">*/}
            {/*    <img src={professional} alt="personal" className="w-5" />*/}
            {/*    <p className="text-[#525866] text-[14px] leading-5 capitalize group-[.is-active]:text-[#0A0D14] font-medium">*/}
            {/*      Professional Info*/}
            {/*    </p>*/}
            {/*  </div>*/}
            {/*  <img*/}
            {/*    src={active}*/}
            {/*    alt="chevron"*/}
            {/*    className="w-5 hidden group-[.is-active]:block"*/}
            {/*  />*/}
            {/*</NavLink>*/}
            <NavLink
              onClick={() => setSelect("security_settings")}
              to="security_settings"
              className={({ isActive }) =>
                `flex justify-between w-full rounded-[8px] group p-2 ${
                  isActive ? "is-active bg-[#F6F8FA]" : ""
                }`
              }
            >
              <div className="flex justify-start items-center gap-2 ">
                <img src={security} alt="personal" className="w-5" />
                <p className="text-[#525866] text-[14px] leading-5 capitalize group-[.is-active]:text-[#0A0D14] font-medium">
                  Security
                </p>
              </div>
              <img
                src={active}
                alt="chevron"
                className="w-5 hidden group-[.is-active]:block"
              />
            </NavLink>
            <NavLink
              onClick={() => setSelect("notification_settings")}
              to="notification_settings"
              className={({ isActive }) =>
                `flex justify-between w-full rounded-[8px] group p-2 ${
                  isActive ? "is-active bg-[#F6F8FA]" : ""
                }`
              }
            >
              <div className="flex justify-start items-center gap-2 ">
                <img src={notification} alt="personal" className="w-5" />
                <p className="text-[#525866] text-[14px] leading-5 capitalize group-[.is-active]:text-[#0A0D14] font-medium">
                  Notification Settings
                </p>
              </div>
              <img
                src={active}
                alt="chevron"
                className="w-5 hidden group-[.is-active]:block"
              />
            </NavLink>
            <NavLink
              onClick={() => setSelect("delete_account")}
              to="delete_account"
              className={({ isActive }) =>
                `flex justify-between w-full rounded-[8px] group p-2 ${
                  isActive ? "is-active bg-[#F6F8FA]" : ""
                }`
              }
            >
              <div className="flex justify-start items-center gap-2 ">
                <img src={deleteIcon} alt="personal" className="w-5" />
                <p className="text-[#525866] text-[14px] leading-5 capitalize group-[.is-active]:text-[#0A0D14] font-medium">
                  Delete Account
                </p>
              </div>
              <img
                src={active}
                alt="chevron"
                className="w-5 hidden group-[.is-active]:block"
              />
            </NavLink>
          </div>
        </div>
        <div className=" w-full nine:w-[67%] laptop:w-[75%] bg-white min-h-[376px] border border-[#E2E4E9] p-4 tablet:px-6 nine:px-4 laptop:px-5 rounded-[10px] nine:rounded-[16px]">
          <SettingsDataContext.Provider value={{ settingsData, refetch }}>
            {settingsData ? (
              <Outlet />
            ) : (
              <div className="mt-6">
                <Loader />
              </div>
            )}
          </SettingsDataContext.Provider>
        </div>
      </div>
    </div>
  );
}

export default Settings;
