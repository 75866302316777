import React, {useEffect} from "react";
import copyimg from "../../Images/copy.png";
import profileImg from "../../Images/profile.png";
import chevron from "../../Images/chevron-right.png";
import toast from "react-hot-toast";
import {useState} from "react";
import {useGetDocumentsQuery, useUploadProfileImgMutation, useEditSettingsMutation} from "@/App/services/settingsApiSlice";
import {Storage} from "@/App/api/storage";
import {useSettingsDataContext} from "@/App/pages/Settings";
import {useDispatch, useSelector} from "react-redux";
import {selectCurrentUser, setCredentials} from "@/App/services/authSlice";
import {useNavigate} from "react-router-dom";
import Credentials from "@/App/components/AccountSettings/Credentials.jsx";
import BioSection from "@/App/components/AccountSettings/Bio.jsx";
import EditModal from "@/App/components/AccountSettings/EditModal.jsx";

function Personal() {

    const [openModal, setModal] = useState(false);
    const [newValue, setNewValue] = useState("");
    const [fieldToChange, setField] = useState("");
    const [editModalTitle, setModalTitle] = useState("");
    const [editModalDesc, setModalDesc] = useState("");
    // const [option, setOption] = useState("");

    const [editSettings, {isLoading}] = useEditSettingsMutation();

    const navigate = useNavigate();
    const {
        data: usersDocuments,
        error: documentError,
        isLoading: documentIsLoading,
        refetch: refetchDocuments,
    } = useGetDocumentsQuery();

    const currentUser = useSelector(selectCurrentUser)
    const {settingsData: personalData, refetch} = useSettingsDataContext();

    const apexId = "A-12341234";
    const handleCopy = async () => {
        try {
            await navigator.clipboard.writeText(apexId);
            toast.success("Copied to clipboard!", {
                id: "clipboard",
                position: "bottom-center",
            });
        } catch (error) {
            console.error("Failed to copy:", error);
            toast.error("Failed to copy ID", {
                position: "bottom-center",
            });
        }
    };


    const [image, setImage] = useState(
        personalData.avatar_id ? personalData.avatar_id : profileImg
    );
    const USER = "MENTRA-USER";
    const user = window ? Storage.getItem(USER) : null;
    const userIdString = user.id.toString();

    const [uploadProfileImg] = useUploadProfileImgMutation();

    async function saveProfileImg(file) {
        const toastId = toast.loading("Uploading image...");
        try {
            const formData = new FormData();
            formData.append("user_id", userIdString);
            formData.append("image", file);

            await uploadProfileImg({formData: formData})
                .unwrap()
                .then((data) => {
                    // dispatch(setCredentials({
                    //   user: { ...currentUser, avatar: data.data.therapist.avatar_id }
                    // }));
                    navigate('/dashboard/settings/personal_info', {replace: true})
                })
                .catch((error) => console.log(error));
            toast.dismiss(toastId);
            toast.success("Profile image uploaded successfully");
            refetch();
        } catch (error) {
            toast.dismiss(toastId);
            console.error("Error uploading profile image:", error);
            toast.error("Error uploading profile image", {
                position: "bottom-center",
            });
        }
    }


    function handleEdit(title, desc, field, value) {
        setModalTitle(title);
        setModalDesc(desc);
        setField(field);
        setNewValue(value)
        setModal(!openModal);
    }


    async function submitHandler(e) {
        e.preventDefault();
        const customProperty = e.target.dataset.custom;


        function isBase64(str) {
            const base64Regex = /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/;
            return base64Regex.test(str);
        }


        if (customProperty) {
            //  convert a file to Base64
            const fileToBase64 = (file) => {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = () => resolve(reader.result);
                    reader.onerror = reject;
                    reader.readAsDataURL(file);
                });
            };

            try {
                let valueToSend = newValue;
                console.log('Before saving ', newValue)
                // Check if newValue is a File or an array of Files
                if (newValue instanceof File) {
                    valueToSend = await fileToBase64(newValue);
                } else if (Array.isArray(newValue) && newValue.every(item => item instanceof File)) {
                    // Convert an array of Files to Base64
                    valueToSend = await Promise.all(newValue.map(fileToBase64));
                }
                const updatedSettings = await editSettings({
                    [fieldToChange]: valueToSend,
                }).unwrap();

                console.log(updatedSettings);
                toast.success("Updated successfully");


                // Refetch documents after successful update or fall to default.
                if (
                    valueToSend instanceof File || // Case 1: A single File object
                    (Array.isArray(valueToSend) && valueToSend.every(item => item instanceof File)) || // Case 2: An array of File objects
                    (typeof valueToSend === 'string' && isBase64(valueToSend)) || // Case 3: A single base64 string
                    (Array.isArray(valueToSend) && valueToSend.every(item => typeof item === 'string' && isBase64(item))) // Case 4: An array of base64 strings
                ) {
                    refetchDocuments();
                } else {
                    refetch();
                }

            } catch (error) {
                toast.error(`Error updating ${editModalTitle}`);
            } finally {
                setModalTitle("");
                setModalDesc("");
                setField("");
                setNewValue("");
                setModal(!openModal);

            }

        }


    }


    const handleImageChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = () => {
            setImage(reader.result); // Set the image state to the data URL of the selected file
            saveProfileImg(file); // Call saveProfileImg after setting the image state
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    console.log('editModalTitle', editModalTitle.toLowerCase())
    return (

        <div style={{height: "53vh", overflow: "scroll"}}>

            <EditModal
                openModal={openModal}
                setModal={setModal}
                editModalTitle={editModalTitle}
                editModalDesc={editModalDesc}
                newValue={newValue}
                setNewValue={setNewValue}
                isLoading={isLoading}
                submitHandler={submitHandler}
            />

            <div>
                <h2 className="font-meduim text-[16px] tablet:text-[18px] leading-6 text-[#0A0D14]">
                    Personal Info
                </h2>
                <p className="text-[#525866] text-[13px] leading-[19px] tablet:text-[14px] tablet:leading-5 pt-[2px] tablet:pt-1">
                    Personalize your privacy settings and enhance the security of your
                    account.
                </p>
            </div>

            <hr className="w-full border-none h-[1px] bg-[#E2E4E9] my-5"/>

            <div className="w-full flex justify-center items-start gap-[4%]">
                <div className="flex w-[48%] h-fit justify-start flex-col items-start">
                    <h3 className="text-[#0A0D14] text-[14px] tablet:text-[15px] leading-5 font-medium">
                        Therapist ID
                    </h3>
                    <p className="text-[12px] tablet:text-[13px] leading-4 text-[#525866] pt-1">
                        {apexId}
                    </p>
                </div>
                <div className="flex w-[48%] h-fit justify-end tablet:justify-start items-end flex-col tablet:items-start">
                    <div
                        onClick={handleCopy}
                        className="p-[6px] border cursor-pointer w-fit border-[#E2E4E9] gap-[2px] rounded-[50px] flex justify-center items-center"
                    >
                        <img src={copyimg} className="w-5" alt="copy id"/>
                        <p className="text-[14px] font-medium leading-5 text-[#525866]">
                            Copy ID
                        </p>
                    </div>
                </div>
            </div>

            <hr className="w-full border-none h-[1px] bg-[#E2E4E9] my-5"/>

            <div className="w-full flex justify-center items-start gap-[4%]">
                <div className="flex w-[48%] h-fit justify-start flex-col items-start">
                    <h3 className="text-[#0A0D14] text-[14px] tablet:text-[15px] leading-5 font-medium">
                        Profile Photo
                    </h3>
                    <p className="text-[12px] tablet:text-[13px] leading-4 text-[#525866] pt-1">
                        Min 400x400px, PNG or JPEG formats.
                    </p>
                </div>
                <div className="flex w-[48%] h-fit justify-end tablet:justify-start items-end flex-col tablet:items-start">
                    <label htmlFor="fileInput" className="cursor-pointer">
                        <div className="flex justify-center items-center gap-2 tablet:gap-3">
                            <img
                                className="size-[45px] rounded-[100%] tablet:size-[56px]"
                                src={image}
                                alt="profileImg"
                            />

                            <p className="text-[14px] font-medium leading-5 text-[#525866] rounded-[50px] border-[#E2E4E9] border px-3 py-[5px]">
                                Upload
                            </p>
                        </div>
                    </label>
                    <input
                        type="file"
                        id="fileInput"
                        accept=".jpg, .jpeg, .png"
                        onChange={handleImageChange}
                        className="file-input-element opacity-0 hidden"
                    />
                </div>
            </div>
            <hr className="w-full border-none h-[1px] bg-[#E2E4E9] my-5"/>

            <div className="w-full flex justify-center items-start gap-[4%]">
                <div className="flex w-[48%] h-fit justify-start flex-col items-start">
                    <h3 className="text-[#0A0D14] text-[14px] tablet:text-[15px] leading-5 font-medium">
                        Full Name
                    </h3>
                    <p className="text-[12px] tablet:text-[13px] leading-4 text-[#525866] pt-1">
                        Your name will be visible to your contacts.
                    </p>
                </div>
                <div className="flex w-[48%] h-fit justify-end tablet:justify-start items-end flex-col tablet:items-start">
                    <div className="w-fit flex flex-col  items-end tablet:items-start">
                        <p className="text-[#0A0D14] capitalize text-[14px] tablet:text-[15px] leading-5">
                            {personalData?.legal_name}
                        </p>
                        {/*<div*/}
                        {/*    onClick={() =>*/}
                        {/*        handleEdit(*/}
                        {/*            "Full Name",*/}
                        {/*            "Your official legal name.",*/}
                        {/*            "legal_name",*/}
                        {/*            personalData?.legal_name*/}
                        {/*        )*/}
                        {/*    }*/}
                        {/*    className="flex cursor-pointer justify-start items-center gap-[2px] pt-1">*/}
                        {/*    <p className="text-[14px] leading-5 font-medium text-[#1A6A73]">*/}
                        {/*        Edit*/}
                        {/*    </p>*/}
                        {/*    <img src={chevron} className="w-5" alt="edit"/>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>

            <hr className="w-full border-none h-[1px] bg-[#E2E4E9] my-5"/>
            <BioSection personalData={personalData} handleUpdate={() =>
                handleEdit(
                    "Biography",
                    "Your bio",
                    "bio",
                    personalData.bio
                )}/>


            <hr className="w-full border-none h-[1px] bg-[#E2E4E9] my-5"/>

            <div className="w-full flex justify-center items-start gap-[4%]">
                <div className="flex w-[48%] h-fit justify-start flex-col items-start">
                    <h3 className="text-[#0A0D14] text-[14px] tablet:text-[15px] leading-5 font-medium">
                        Option that describes you
                    </h3>
                    <p className="text-[12px] tablet:text-[13px] leading-4 text-[#525866] pt-1">
                        Here is the option you chose to describe yourself
                    </p>
                </div>
                <div className="flex w-[48%] h-fit justify-end tablet:justify-start items-end flex-col tablet:items-start">
                    <div className="w-fit flex flex-col  items-end tablet:items-start">
                        <p className="text-[#0A0D14] text-right tablet:text-left capitalize text-[14px] tablet:text-[15px] leading-5">
                            {personalData?.title}
                        </p>
                        <div
                            onClick={() =>
                                handleEdit(
                                    "Option that describes you",
                                    "option that describe you best",
                                    "title",
                                    personalData?.title
                                )
                            }

                            className="flex cursor-pointer justify-start items-center gap-[2px] pt-1">
                            <p className="text-[14px] leading-5 font-medium text-[#1A6A73]">
                                Edit
                            </p>
                            <img src={chevron} className="w-5" alt="edit"/>
                        </div>
                    </div>
                </div>
            </div>


            <hr className="w-full border-none h-[1px] bg-[#E2E4E9] my-5"/>

            <div className="w-full flex justify-center items-start gap-[4%]">
                <div className="flex w-[48%] h-fit justify-start flex-col items-start">
                    <p className="text-[#0A0D14] text-[14px] tablet:text-[15px] leading-5 font-medium">
                        Gender
                    </p>
                    <p className="text-[12px] tablet:text-[13px] leading-4 text-[#525866] pt-1">
                        Please select your gender.
                    </p>
                </div>
                <div className="flex w-[48%] h-fit justify-end tablet:justify-start items-end flex-col tablet:items-start">
                    <div className="w-fit flex flex-col  items-end tablet:items-start">
                        <h3 className="text-[#0A0D14] capitalize text-[14px] tablet:text-[15px] leading-5">
                            {personalData.gender}
                        </h3>
                        <div
                            onClick={() =>
                                handleEdit(
                                    "Gender",
                                    "Your gender",
                                    "gender",
                                    personalData?.gender
                                )
                            }
                            className="flex cursor-pointer justify-start items-center gap-[2px] pt-1">
                            <p className="text-[14px] leading-5 font-medium text-[#1A6A73]">
                                Edit
                            </p>
                            <img src={chevron} className="w-5" alt="edit"/>
                        </div>
                    </div>
                </div>
            </div>


            <hr className="w-full border-none h-[1px] bg-[#E2E4E9] my-5"/>
            <div className="w-full flex justify-center items-start gap-[4%]">
                {/* Nationality Section */}
                <div className="flex w-[48%] h-fit justify-start flex-col items-start">
                    <h3 className="text-[#0A0D14] text-[14px] tablet:text-[15px] leading-5 font-medium">
                        Nationality
                    </h3>
                    <p className="text-[12px] tablet:text-[13px] leading-4 text-[#525866] pt-1">
                        Your country of citizenship or nationality.
                    </p>
                </div>
                <div className="flex w-[48%] h-fit justify-end tablet:justify-start items-end flex-col tablet:items-start">
                    <div className="w-fit flex flex-col items-end tablet:items-start">
                        <h3 className="text-[#0A0D14] capitalize text-[14px] tablet:text-[15px] leading-5">
                            {personalData?.nationality}
                        </h3>
                        <div
                            onClick={() =>
                                handleEdit(
                                    "Nationality",
                                    "Your country of origin.",
                                    "nationality",
                                    personalData?.nationality
                                )
                            }
                            className="flex cursor-pointer justify-start items-center gap-[2px] pt-1">
                            <p className="text-[14px] leading-5 font-medium text-[#1A6A73]">
                                Edit
                            </p>
                            <img src={chevron} className="w-5" alt="edit"/>
                        </div>
                    </div>
                </div>
            </div>

            <hr className="w-full border-none h-[1px] bg-[#E2E4E9] my-5"/>

            <div className="w-full flex justify-center items-start gap-[4%]">
                {/* Languages Spoken Section */}
                <div className="flex w-[48%] h-fit justify-start flex-col items-start">
                    <h3 className="text-[#0A0D14] text-[14px] tablet:text-[15px] leading-5 font-medium">
                        Languages Spoken
                    </h3>
                    <p className="text-[12px] tablet:text-[13px] leading-4 text-[#525866] pt-1">
                        List the languages you are fluent in.
                    </p>
                </div>
                <div className="flex w-[48%] h-fit justify-end tablet:justify-start items-end flex-col tablet:items-start">
                    <div className="w-fit flex flex-col items-end tablet:items-start">
                        <div className="w-full flex flex-wrap justify-end laptop:justify-start gap-2 items-start">
                            {personalData.languages_spoken?.map((language, index) => (
                                <p
                                    key={index}
                                    className="bg-[#F6F8FA] text-[12px] font-semibold leading-4 text-[#525866] py-[2px] rounded-[50px]"
                                >
                                    {language}
                                </p>
                            ))}
                        </div>
                        <div
                            onClick={() =>
                                handleEdit(
                                    "Languages Spoken",
                                    "languages spoken.",
                                    "languages_spoken",
                                    personalData?.languages_spoken
                                )
                            }
                            className="flex cursor-pointer justify-start items-center gap-[2px] pt-1 mt-3"
                        >
                            <p className="text-[14px] leading-5 font-medium text-[#1A6A73]">
                                Edit
                            </p>
                            <img src={chevron} className="w-5" alt="edit"/>
                        </div>
                    </div>

                </div>
            </div>

            <hr className="w-full border-none h-[1px] bg-[#E2E4E9] my-5"/>

            <div className="w-full flex justify-center items-start gap-[4%]">
                {/* Country of Residence Section */}
                <div className="flex w-[48%] h-fit justify-start flex-col items-start">
                    <h3 className="text-[#0A0D14] text-[14px] tablet:text-[15px] leading-5 font-medium">
                        Country of Residence
                    </h3>
                    <p className="text-[12px] tablet:text-[13px] leading-4 text-[#525866] pt-1">
                        Where you currently live.
                    </p>
                </div>
                <div className="flex w-[48%] h-fit justify-end tablet:justify-start items-end flex-col tablet:items-start">
                    <div className="w-fit flex flex-col items-end tablet:items-start">
                        <div className="w-full flex flex-wrap justify-end laptop:justify-start gap-2 items-start">
                            {personalData.countries_lived_in?.map((item, index) => (
                                <p
                                    key={index}
                                    className="bg-[#F6F8FA] text-[12px] font-semibold leading-4 text-[#525866] py-[2px] rounded-[50px]"
                                >
                                    {item}
                                </p>
                            ))}
                        </div>

                        <div
                            onClick={() =>
                                handleEdit(
                                    "Country of Residence",
                                    "Your country of residence.",
                                    "countries_lived_in",
                                    personalData?.countries_lived_in
                                )
                            }
                            className="flex cursor-pointer justify-start items-center gap-[2px] pt-1">
                            <p className="text-[14px] leading-5 font-medium text-[#1A6A73]">
                                Edit
                            </p>
                            <img src={chevron} className="w-5" alt="edit"/>
                        </div>
                    </div>
                </div>
            </div>


            <hr className="w-full border-none h-[1px] bg-[#E2E4E9] my-5"/>

            {/* Credentials Section */}
            <div className="w-full gap-4  flex flex-col laptop:flex-row justify-center items-center nine:items-start laptop:gap-[4%]">
                <div className="flex w-full laptop:w-[48%] h-fit justify-start flex-col items-start">
                    <h3 className="text-[#0A0D14] text-[14px] tablet:text-[15px] leading-5 font-medium">Credentials</h3>
                    <p className="text-[12px] tablet:text-[13px] leading-4 text-[#525866] pt-1">Your valid credentials and licenses.</p>
                </div>

                <Credentials documentIsLoading={documentIsLoading}
                             usersDocuments={usersDocuments}
                             handleUpdate={() =>
                                 handleEdit(
                                     "Credentials",
                                     "Your valid credentials and licenses",
                                     "certifications",
                                     personalData?.certifications
                                 )}
                />
            </div>


            <hr className="w-full border-none h-[1px] bg-[#E2E4E9] my-5"/>

            <div className="w-full gap-6  flex flex-col laptop:flex-row justify-center items-center nine:items-start laptop:gap-[4%]">
                <div className="flex w-full laptop:w-[48%] h-fit justify-start flex-col items-start">
                    <h3 className="text-[#0A0D14] text-[14px] tablet:text-[15px] leading-5 font-medium">
                        What conditions do you treat?
                    </h3>
                    <p className="text-[12px] tablet:text-[13px] leading-4 text-[#525866] pt-1">
                        A list of conditions you treat.
                    </p>
                </div>
                <div className="flex w-full laptop:w-[48%] h-fit justify-end tablet:justify-start items-end flex-col tablet:items-start">
                    <div className="w-fit flex flex-col  items-end tablet:items-start">
                        <div className="w-full flex flex-wrap justify-end laptop:justify-start gap-2 items-start">
                            {personalData.treatable_conditions?.map((item, index) => (
                                <p
                                    key={index}
                                    className="bg-[#F6F8FA] text-[12px] font-semibold leading-4 text-[#525866] py-[2px] rounded-[50px]"
                                >
                                    {item}
                                </p>
                            ))}
                        </div>
                        <div

                            onClick={() =>
                                handleEdit(
                                    "What conditions do you treat",
                                    "Conditions i treat.",
                                    "treatable_conditions",
                                    personalData?.treatable_conditions
                                )
                            }
                            className="flex cursor-pointer justify-start items-center gap-[2px] pt-1 mt-3"
                            // className="flex cursor-pointer mt-3 ml-auto laptop:ml-[0] nine:pl-2 w-fit justify-start items-center gap-[2px] pt-1"
                        >
                            <p className="text-[14px] leading-5 font-medium text-[#1A6A73]">
                                Edit
                            </p>
                            <img src={chevron} className="w-5" alt="edit"/>
                        </div>
                    </div>
                </div>
            </div>


            <hr className="w-full border-none h-[1px] bg-[#E2E4E9] my-5"/>

            <div className="w-full flex justify-center items-start gap-[4%]">
                {/* Techniques of Expertise Section */}
                <div className="flex w-[48%] h-fit justify-start flex-col items-start">
                    <h3 className="text-[#0A0D14] text-[14px] tablet:text-[15px] leading-5 font-medium">
                        Techniques of Expertise
                    </h3>
                    <p className="text-[12px] tablet:text-[13px] leading-4 text-[#525866] pt-1">
                        Your areas of expertise or specialization.
                    </p>
                </div>

                <div className="flex w-full laptop:w-[48%] h-fit justify-end tablet:justify-start items-end flex-col tablet:items-start">
                    <div className="w-fit flex flex-col items-end tablet:items-start">
                        <div className="w-full flex flex-wrap justify-end laptop:justify-start gap-2 items-start">
                            {personalData?.techniques_of_expertise &&
                            Array.isArray(personalData.techniques_of_expertise) ? (
                                personalData.techniques_of_expertise.map((technique, index) => (
                                    <p
                                        key={index}
                                        className="bg-[#F6F8FA] text-[12px] font-semibold leading-4 text-[#525866] py-[2px] rounded-[50px]"
                                    >
                                        {technique}
                                    </p>
                                ))
                            ) : (
                                <p className="text-[#525866] text-[12px]">No techniques available</p>
                            )}
                        </div>
                        <div
                            onClick={() =>
                                handleEdit(
                                    'What techniques are you an expert in',
                                    'Techniques that describe you best',
                                    'techniques_of_expertise',
                                    personalData?.techniques_of_expertise
                                )
                            }
                            className="flex cursor-pointer justify-start items-center gap-[2px] pt-1 mt-3"
                            // className="flex cursor-pointer mt-3 ml-auto laptop:ml-[0] nine:pl-2 w-fit justify-start items-center gap-[2px] pt-1"
                        >
                            <p className="text-[14px] leading-5 font-medium text-[#1A6A73]">
                                Edit
                            </p>
                            <img src={chevron} className="w-5" alt="edit"/>
                        </div>
                    </div>
                </div>

            </div>


            <hr className="w-full border-none h-[1px] bg-[#E2E4E9] my-5"/>

            <div className="w-full flex justify-center items-start gap-[4%]">
                <div className="flex w-[48%] h-fit justify-start flex-col items-start">
                    <h3 className="text-[#0A0D14] text-[14px] tablet:text-[15px] leading-5 font-medium">
                        Email Address
                    </h3>
                    <p className="text-[12px] tablet:text-[13px] leading-4 text-[#525866] pt-1">
                        Business email address recommended.
                    </p>
                </div>
                <div className="flex w-[48%] h-fit justify-end tablet:justify-start items-end flex-col tablet:items-start">
                    <div className="w-fit flex flex-col  items-end tablet:items-start">
                        <p className="text-[#0A0D14] text-[14px] tablet:text-[15px] leading-5">
                            {personalData?.email_address}
                        </p>

                        {/*<div*/}
                        {/*    onClick={() =>*/}
                        {/*        handleEdit(*/}
                        {/*            "Email Address",*/}
                        {/*            "Your email address.",*/}
                        {/*            "email_address",*/}
                        {/*            personalData?.email_address*/}
                        {/*        )*/}
                        {/*    }*/}
                        {/*    className="flex cursor-pointer justify-start items-center gap-[2px] pt-1">*/}
                        {/*    <p className="text-[14px] leading-5 font-medium text-[#1A6A73]">*/}
                        {/*        Edit*/}
                        {/*    </p>*/}
                        {/*    <img src={chevron} className="w-5" alt="edit"/>*/}
                        {/*</div>*/}

                    </div>
                </div>
            </div>
            <hr className="w-full border-none h-[1px] bg-[#E2E4E9] my-5"/>

            <div className="w-full flex justify-center items-start gap-[4%]">
                <div className="flex w-[48%] h-fit justify-start flex-col items-start">
                    <h3 className="text-[#0A0D14] text-[14px] tablet:text-[15px] leading-5 font-medium">
                        Phone Number
                    </h3>
                    <p className="text-[12px] tablet:text-[13px] leading-4 text-[#525866] pt-1">
                        Business phone number recommended.
                    </p>
                </div>
                <div className="flex w-[48%] h-fit justify-end tablet:justify-start items-end flex-col tablet:items-start">
                    <div className="w-fit flex flex-col  items-end tablet:items-start">
                        <p className="text-[#0A0D14] capitalize text-[14px] tablet:text-[15px] leading-5">
                            {personalData?.phone_number}
                        </p>
                        <div
                            onClick={() =>
                                handleEdit(
                                    "Phone Number",
                                    "Your phone number.",
                                    "phone_number",
                                    personalData?.phone_number
                                )
                            }
                            className="flex cursor-pointer justify-start items-center gap-[2px] pt-1">
                            <p className="text-[14px] leading-5 font-medium text-[#1A6A73]">
                                Edit
                            </p>
                            <img src={chevron} className="w-5" alt="edit"/>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    );
}

export default Personal;

{
    /* The tremor ui libary is forcing a border on every input thats ehy i have to set the borders using inline styles and add !important to the tailwind styles*/
}
