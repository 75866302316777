import React from "react";
import PropTypes from "prop-types";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle
} from "@/Components/ui/dialog.jsx";
import {Label} from "@/Components/ui/label.jsx";
import {ContactLoader} from "@/Components/loader.jsx";
import arrDown from "../../../assets/Assets/Images/arrow-down.png";
import search from "../../../assets/Assets/Images/search.png";
import cloud from "../../../assets/Assets/Images/cloud.png";
import png from "../../../assets/Assets/Images/png-icon.png";
import pdf from "../../../assets/Assets/Images/pdf-icon.png";
import jpg from "../../../assets/Assets/Images/jpg-icon.png";
import doc from "../../../assets/Assets/Images/doc-icon.png";
import docx from "../../../assets/Assets/Images/docx-icon.png";
import deleteIcon from "../../../assets/Assets/Images/delete-icon.png";

import {useState, useEffect} from "react";
import axios from "axios";
import {conditions, conditions_treat, mostSpokenLanguages} from "@/App/components/AccountSettings/constanst.js";

function EditModal({
                       openModal,
                       setModal,
                       editModalTitle,
                       editModalDesc,
                       newValue,
                       setNewValue,
                       isLoading,
                       submitHandler,
                   }) {


    const [nationality, setNationality] = useState('');
    const [nationalityModal, setNationalityModal] = useState(false);
    const [countries, setCountries] = useState([]); // Store fetched countries
    const [nationalitySearch, setNationalitySearch] = useState(""); // Search input

    const [selectedCountries, setSelectedCountries] = useState(newValue || []);


    const handleCountrySelection = (country) => {
        setSelectedCountries((prevSelected) => {
            if (prevSelected.includes(country)) {
                // Remove the country if it's already selected
                const updatedSelection = prevSelected.filter((item) => item !== country);
                setNewValue(updatedSelection);
                return updatedSelection;
            } else {
                // Add the country to the selection
                const updatedSelection = [...prevSelected, country];
                setNewValue(updatedSelection);
                return updatedSelection;
            }
        });
    };

    const handleAddCountry = (countryName) => {
        if (!selectedCountries.includes(countryName)) {
            const updatedCountries = [...selectedCountries, countryName];
            setSelectedCountries(updatedCountries);
            setNewValue(updatedCountries);
        }
        setNationalityModal(false);
    };

    const handleRemoveCountry = (countryName) => {
        const updatedCountries = selectedCountries.filter(
            (name) => name !== countryName
        );
        setSelectedCountries(updatedCountries);
        setNewValue(updatedCountries);
    };


    const [files, setFiles] = useState(newValue || []);

    const [option, setOption] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [filled, _] = useState(true);


    // setup options
    useEffect(() => {
        if (editModalTitle?.toLowerCase() === "languages spoken" ||
            editModalTitle?.toLowerCase() === 'what conditions do you treat' ||
            editModalTitle?.toLowerCase() === "option that describes you" ||
            editModalTitle?.toLowerCase() === "what techniques are you an expert in") {
            setOption(newValue || []);
        }
    }, [editModalTitle]);


    // Clean Up
    useEffect(() => {
        // Only close the nationality modal if it's the nationality or country of residence modal close
        if (!openModal && (editModalTitle?.toLowerCase() === "nationality" || editModalTitle?.toLowerCase() === "country of residence")) {
            setNationalityModal(false);
        }

        // clear files too if modal os close
        if (!openModal) {
            setFiles([]);
        }


    }, [openModal, editModalTitle]);


    // Fetch countries on component mount
    useEffect(() => {
        if (editModalTitle?.toLowerCase() === "nationality" || editModalTitle?.toLowerCase() === "country of residence") {
            fetchCountries();
        }
        setNationality(newValue);
    }, [editModalTitle]);


    const fetchCountries = async () => {
        try {
            const response = await axios.get("https://restcountries.com/v3.1/all");
            const countriesData = response.data
                .map((country) => ({
                    name: country.demonyms?.eng?.f || "Unknown",
                    flag: country.flags?.png || "",
                }))
                .sort((a, b) => a.name.localeCompare(b.name));
            setCountries(countriesData);
        } catch (error) {
            console.error("Failed to fetch countries", error);
        }
    };


    const handleNationalityModal = () => setNationalityModal(!nationalityModal);

    const handleNationality = (value) => {
        setNationality(value);
        setNewValue(value);
        setNationalityModal(false);
    };

    const filteredCountries = countries.filter((country) =>
        country.name.toLowerCase().includes(nationalitySearch.toLowerCase())
    );


    function handleOption(e) {
        const value = e.target.value;
        if (option.includes(value)) {
            setOption((prevOptions) => {
                const newOptions = prevOptions.filter((opt) => opt !== value);
                setNewValue(newOptions);
                return newOptions;
            });
        } else {
            setOption((prevOptions) => {
                const newOptions = [value, ...prevOptions];
                setNewValue(newOptions);
                return newOptions;
            });
        }
    }


    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];

        if (selectedFile && !files.some((f) => f.name === selectedFile.name)) {
            setFiles((prevFiles) => {
                const newFiles = [...prevFiles, selectedFile];
                setNewValue(newFiles);
                return newFiles;
            });
            console.log('from selectedFile', selectedFile);
        }
    };

    const handleDeleteFile = (name) => {
        setFiles((prevFiles) => {
            const newFiles = prevFiles.filter((f) => f.name !== name);
            setNewValue(newFiles);
            return newFiles;
        });
    };

    // const handleFileChange = (e) => {
    //     const selectedFile = e.target.files[0];
    //
    //     if (selectedFile && !files.some((f) => f.name === selectedFile.name)) {
    //         setFiles((prevFiles) => [...prevFiles, selectedFile]);
    //         console.log('from selectedFile', selectedFile);
    //
    //
    //         setNewValue(files);
    //     }
    // };
    //
    // const handleDeleteFile = (name) => {
    //     const newFiles = files.filter((f) => f.name !== name);
    //     setFiles(newFiles);
    // };


    return (
        <Dialog open={openModal} onOpenChange={setModal}>
            <DialogContent className="">
                <DialogHeader className="*:text-left">
                    <DialogTitle>Edit {editModalTitle}</DialogTitle>
                    <DialogDescription>{editModalDesc}</DialogDescription>
                </DialogHeader>
                <form onSubmit={submitHandler}>
                    <div className="mt-3">
                        <Label
                            htmlFor="name"
                            className="text-left font-semibold text-[#0A0D14]"
                        >
                            New {editModalTitle}
                        </Label>

                        {/* Nationality input block */}
                        {editModalTitle?.toLowerCase() === "nationality" ? (
                            <div className="bg-[#F9F7F3] mt-[3%] w-[450px] text-[13px] py-[3%] px-[5%] rounded-[30px] max-[1130px]:rounded-[20px] max-[767px]:w-[100%] max-[767px]:rounded-[15px]">
                                <p className="text-[15px] font-semibold text-[#191B1E] max-[767px]:text-[14px]">
                                    Nationality
                                </p>
                                <div className="w-full relative text-[15px] cursor-pointer font-semibold h-[30px] max-[767px]:text-[14px]">
                                    <div
                                        onClick={handleNationalityModal}
                                        className="flex justify-between items-center h-full w-full"
                                    >
                                        <p>
                                            {nationality ? (
                                                nationality
                                            ) : (
                                                <span className="text-zinc-400">
                                                Select an option
                                            </span>
                                            )}
                                        </p>
                                        <img
                                            src={arrDown}
                                            className="h-[7px] pr-[6px]"
                                            alt="menu"
                                        />
                                    </div>
                                    {nationalityModal && (
                                        <div
                                            style={{
                                                scrollbarWidth: "none",
                                                msOverflowStyle: "none",
                                            }}
                                            className="w-full overflow-y-scroll z-10 overscroll-none flex flex-col justify-start items-start absolute top-[100%] rounded-md h-[250px] bg-[#ffffff] border-2"
                                        >
                                            <div className="border-b-2 gap-3 flex w-full px-3 py-2 items-center">
                                                <img
                                                    src={search}
                                                    alt="search"
                                                    className="w-[20px]"
                                                />
                                                <input
                                                    id="nationality"
                                                    value={nationalitySearch}
                                                    onChange={(e) => setNationalitySearch(e.target.value)}
                                                    className="border-l-2 pl-3 font-medium text-zinc-500"
                                                    type="text"
                                                    placeholder="Search nationality"
                                                />
                                            </div>
                                            {filteredCountries.map((item, index) => (
                                                <div
                                                    className="block w-full"
                                                    key={index}
                                                    onClick={() =>
                                                        handleNationality(item.name)
                                                    }
                                                >
                                                    <div
                                                        className={`flex justify-start px-3 ${
                                                            nationality === item.name
                                                                ? "bg-[#1A6A73] text-white"
                                                                : ""
                                                        } py-2 hover:bg-[#1A6A73] hover:text-white gap-4 text-[#191B1E] items-center text-left`}
                                                    >
                                                        <img
                                                            src={item.flag}
                                                            alt=""
                                                            className="w-8 rounded-[4px] overflow-hidden"
                                                        />
                                                        <p>{item.name}</p>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                    <div
                                        onClick={handleNationalityModal}
                                        className={`w-screen top-0 left-0 ${
                                            nationalityModal ? "" : "hidden"
                                        } h-screen fixed z-1`}
                                    ></div>
                                </div>
                            </div>
                        ) : editModalTitle?.toLowerCase() === "country of residence" ? (
                                // COUNTRY OF RESIDENCE
                                <div>
                                    {/* Selected Countries */}
                                    <div className="flex flex-wrap gap-2 mt-2">
                                        {selectedCountries.map((country, index) => (
                                            <div
                                                onClick={() => handleCountrySelection(country)}
                                                key={index}
                                                className="flex items-center px-3 py-1 bg-[#1A6A73] text-white rounded-full text-sm"
                                            >
                                                <span onClick={() => handleRemoveCountry(country)}>{country}</span>
                                                <button
                                                    type="button"
                                                    className="ml-2 text-white"
                                                >
                                                    ✕
                                                </button>
                                            </div>
                                        ))}
                                    </div>

                                    {/* Dropdown */}
                                    <div className="relative mt-4">
                                        <div
                                            onClick={handleNationalityModal}
                                            className="flex justify-between items-center bg-[#F9F7F3] px-4 py-2 rounded-lg cursor-pointer"
                                        >
                                        <span className="text-[#191B1E] font-semibold">
                                            Select Country of Residence
                                        </span>
                                            <img src={arrDown} alt="arrow down" className="h-3"/>
                                        </div>
                                        {nationalityModal && (
                                            <div className="absolute z-10 w-full mt-2 bg-white border rounded-lg shadow-lg">
                                                <div className="flex items-center gap-2 px-3 py-2 border-b">
                                                    <img
                                                        src={search}
                                                        alt="search"
                                                        className="w-5"
                                                    />
                                                    <input
                                                        type="text"
                                                        value={nationalitySearch}
                                                        onChange={(e) =>
                                                            setNationalitySearch(e.target.value)
                                                        }
                                                        className="w-full px-2 py-1 text-sm border-l"
                                                        placeholder="Search countries"
                                                    />
                                                </div>
                                                <div className="max-h-48 overflow-y-auto">
                                                    {filteredCountries.map((country, index) => (
                                                        <div
                                                            key={index}
                                                            className="flex items-center gap-2 px-3 py-2 cursor-pointer hover:bg-[#1A6A73] hover:text-white"
                                                            onClick={() =>
                                                                handleAddCountry(country.name)
                                                            }
                                                        >
                                                            <img
                                                                src={country.flag}
                                                                alt=""
                                                                className="w-6 rounded-sm"
                                                            />
                                                            <span>{country.name}</span>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                // COUNTRY OF RESIDENCE
                                // <div className="bg-[#F9F7F3] mt-[3%] w-[450px] text-[13px] py-[3%] px-[5%] rounded-[30px] max-[1130px]:rounded-[20px] max-[767px]:w-[100%] max-[767px]:rounded-[15px]">
                                //     <p className="text-[15px] font-semibold text-[#191B1E] max-[767px]:text-[14px]">
                                //         Country of Residence
                                //     </p>
                                //     <div className="w-full relative text-[15px] cursor-pointer font-semibold h-[30px] max-[767px]:text-[14px]">
                                //         <div
                                //             onClick={handleNationalityModal}
                                //             className="flex justify-between items-center h-full w-full"
                                //         >
                                //             <p>
                                //                 {nationality ? (
                                //                     nationality
                                //                 ) : (
                                //                     <span className="text-zinc-400">
                                //                 Select an option
                                //             </span>
                                //                 )}
                                //             </p>
                                //             <img
                                //                 src={arrDown}
                                //                 className="h-[7px] pr-[6px]"
                                //                 alt="menu"
                                //             />
                                //         </div>
                                //         {nationalityModal && (
                                //             <div
                                //                 style={{
                                //                     scrollbarWidth: "none",
                                //                     msOverflowStyle: "none",
                                //                 }}
                                //                 className="w-full overflow-y-scroll z-10 overscroll-none flex flex-col justify-start items-start absolute top-[100%] rounded-md h-[250px] bg-[#ffffff] border-2"
                                //             >
                                //                 <div className="border-b-2 gap-3 flex w-full px-3 py-2 items-center">
                                //                     <img
                                //                         src={search}
                                //                         alt="search"
                                //                         className="w-[20px]"
                                //                     />
                                //                     <input
                                //                         id="nationality"
                                //                         value={nationalitySearch}
                                //                         onChange={(e) => setNationalitySearch(e.target.value)}
                                //                         className="border-l-2 pl-3 font-medium text-zinc-500"
                                //                         type="text"
                                //                         placeholder="Search country of residence"
                                //                     />
                                //                 </div>
                                //                 {filteredCountries.map((item, index) => (
                                //                     <div
                                //                         className="block w-full"
                                //                         key={index}
                                //                         onClick={() =>
                                //                             handleNationality(item.name)
                                //                         }
                                //                     >
                                //                         <div
                                //                             className={`flex justify-start px-3 ${
                                //                                 nationality === item.name
                                //                                     ? "bg-[#1A6A73] text-white"
                                //                                     : ""
                                //                             } py-2 hover:bg-[#1A6A73] hover:text-white gap-4 text-[#191B1E] items-center text-left`}
                                //                         >
                                //                             <img
                                //                                 src={item.flag}
                                //                                 alt=""
                                //                                 className="w-8 rounded-[4px] overflow-hidden"
                                //                             />
                                //                             <p>{item.name}</p>
                                //                         </div>
                                //                     </div>
                                //                 ))}
                                //             </div>
                                //         )}
                                //         <div
                                //             onClick={handleNationalityModal}
                                //             className={`w-screen top-0 left-0 ${
                                //                 nationalityModal ? "" : "hidden"
                                //             } h-screen fixed z-1`}
                                //         ></div>
                                //     </div>
                                // </div>

                            )
                            : editModalTitle?.toLowerCase() === 'biography' ? (
                                <>
                                <textarea
                                    style={{
                                        outline: "none",
                                        border: "1.5px solid #E2E4E9",
                                        boxShadow: "none",
                                        height: "150px",
                                        resize: "none"
                                    }}
                                    value={newValue}
                                    onChange={(e) => setNewValue(e.target.value)}
                                    id="name"
                                    required
                                    placeholder="Enter new info"
                                    maxLength={300}
                                    className="w-full text border border-[#E2E4E9] rounded-[10px] font-medium text-[14px] pl-3 py-[6px] focus:outline-red-500! mt-1"
                                />
                                    <div className="text-right text-[12px] text-gray-500 mt-1">
                                        {newValue.length}/300 characters
                                    </div>
                                </>
                            ) : editModalTitle?.toLowerCase() === "gender" ? (
                                <select
                                    id="gender"
                                    name="gender"
                                    value={newValue}
                                    onChange={(e) => setNewValue(e.target.value)}
                                    className="mt-1 block w-full h-[30px] pl-2 px-0 text-[15px] max-[767px]:text-[14px] font-semibold py-0 bg-transparent border-gray-400 focus:border-gray-400 focus:outline-none sm:text-sm rounded-md"
                                >
                                    <option value="" disabled>
                                        Select your gender
                                    </option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                </select>
                            ) : editModalTitle?.toLowerCase() ===
                            "option that describes you" ? (
                                <div className="flex flex-col justify-center items-center gap-4 mt-2 mb-6 w-full">
                                    <button
                                        value="Clinical Psychologist"
                                        onClick={(e) => setNewValue(e.target.value)}
                                        className={`w-[90%] ${
                                            newValue === "Clinical Psychologist"
                                                ? "bg-[#1A6A73] text-white"
                                                : ""
                                        } ease-in-out text-center border-[2px] py-[3%] border-[#F9F7F3] rounded-[30px]`}
                                    >
                                        Clinical Psychologist
                                    </button>
                                    <button
                                        value="Mental Health Coach / Psychologist"
                                        onClick={(e) => setNewValue(e.target.value)}
                                        className={`w-[90%] ${
                                            newValue === "Mental Health Coach / Psychologist"
                                                ? "bg-[#1A6A73] text-white"
                                                : ""
                                        } ease-in-out text-center border-[2px] py-[3%] border-[#F9F7F3] rounded-[30px]`}
                                    >
                                        Mental Health Coach / Psychologist
                                    </button>
                                    <button
                                        value="Mental Health Counselor"
                                        onClick={(e) => setNewValue(e.target.value)}
                                        className={`w-[90%] ${
                                            newValue === "Mental Health Counselor"
                                                ? "bg-[#1A6A73] text-white"
                                                : ""
                                        } ease-in-out text-center border-[2px] py-[3%] border-[#F9F7F3] rounded-[30px]`}
                                    >
                                        Mental Health Counselor
                                    </button>
                                </div>
                            ) : editModalTitle?.toLowerCase() === 'what techniques are you an expert in' ? (

                                <div className="w-[90%] px-[2%] pb-[10%] rounded-[20px] text-center">
                                    <p
                                        className={`text-red-700 w-full ${
                                            filled ? 'opacity-0' : 'opacity-1'
                                        } tracking-wider text-center my-2`}
                                    >
                                        Please select an option!
                                    </p>
                                    <div className="w-[100%] text-left">
                                        {conditions.map((word, index) => (
                                            <button
                                                value={word}
                                                onClick={handleOption}
                                                className={`px-[4%] m-[1%] ${
                                                    option.includes(word)
                                                        ? 'bg-[#1A6A73] text-white'
                                                        : ''
                                                } border-[2px] border-[#F9F7F3] rounded-[30px] py-[2%]`}
                                                key={index}
                                            >
                                                {word}
                                            </button>
                                        ))}
                                    </div>
                                </div>

                            ) : editModalTitle?.toLowerCase() === 'what conditions do you treat' ? (

                                <div className="w-[90%] px-[2%] pb-[10%] rounded-[20px] text-center">
                                    <p
                                        className={`text-red-700 w-full ${
                                            filled ? 'opacity-0' : 'opacity-1'
                                        } tracking-wider text-center my-2`}
                                    >
                                        Please select an option!
                                    </p>
                                    <div className="w-[100%] text-left">
                                        {conditions_treat.map((word, index) => (
                                            <button
                                                value={word}
                                                onClick={handleOption}
                                                className={`px-[4%] m-[1%] ${
                                                    option.includes(word)
                                                        ? 'bg-[#1A6A73] text-white'
                                                        : ''
                                                } border-[2px] border-[#F9F7F3] rounded-[30px] py-[2%]`}
                                                key={index}
                                            >
                                                {word}
                                            </button>
                                        ))}
                                    </div>
                                </div>

                            ) : editModalTitle?.toLowerCase() === 'languages spoken' ? (

                                    <div className="w-[90%] px-[2%] pb-[10%] rounded-[20px] text-center">
                                        <p
                                            className={`text-red-700 w-full ${
                                                filled ? 'opacity-0' : 'opacity-1'
                                            } tracking-wider text-center my-2`}
                                        >
                                            Please select an option!
                                        </p>
                                        <div className="w-[100%] text-left">
                                            {mostSpokenLanguages.map((word, index) => (
                                                <button
                                                    value={word}
                                                    onClick={handleOption}
                                                    className={`px-[4%] m-[1%] ${
                                                        option.includes(word)
                                                            ? 'bg-[#1A6A73] text-white'
                                                            : ''
                                                    } border-[2px] border-[#F9F7F3] rounded-[30px] py-[2%]`}
                                                    key={index}
                                                >
                                                    {word}
                                                </button>
                                            ))}
                                        </div>
                                    </div>

                                )
                                : editModalTitle?.toLowerCase() === 'credentials' ? (
                                        <div>
                                            <p
                                                className={` text-red-700 w-full ${
                                                    filled ? "opacity-0" : "opacity-1"
                                                } tracking-wider text-center my-2`}
                                            >
                                                Please upload credentials!
                                            </p>
                                            <div className=" border-dashed border-2 border-[#E7E7E0] w-[90%] px-[2%] pt-8 pb-4 rounded-[20px] text-center">
                                                <div className="text-center">
                                                    <img src={cloud} alt="cloud" className="mx-auto w-[28px]"/>
                                                    <h1 className="text-[18px] font-semibold">Choose a file <p className="text-[14px]">multiple uploads allowed</p></h1>
                                                    <p className="mt-[2%]">JPEG, PNG, PDF formats, up to 50 MB.</p>
                                                </div>
                                                <div className="mt-10 ">
                                                    <label
                                                        htmlFor="credentials"
                                                        className="bg-[white] cursor-pointer font-semibold shadow-md text-center px-5 mx-auto py-[3%]  text-[#000000] border-[1px] rounded-[30px] max-[767px]:w-[70%]"
                                                    >
                                                        Browse Files
                                                    </label>
                                                    <input
                                                        type="file"
                                                        id="credentials"
                                                        accept=".jpg, .jpeg, .png, .pdf, .doc, .docx"
                                                        onChange={handleFileChange}
                                                        className="file-input-element opacity-0"
                                                        multiple="multiple"
                                                    />
                                                </div>
                                            </div>
                                            {files.length > 0 &&
                                                files.map((file, index) => (
                                                    <div
                                                        key={index}
                                                        className="w-[80%] py-4 px-5 mt-5 rounded-[20px] border-2 border-[#E7E7E0] flex justify-between items-center"
                                                    >
                                                        <div className="flex justify-start items-center max-w-[90%] gap-3">
                                                            <img
                                                                src={
                                                                    file?.name.includes(".png")
                                                                        ? png
                                                                        : file?.name.includes(".pdf")
                                                                            ? pdf
                                                                            : file?.name.includes(".doc")
                                                                                ? doc
                                                                                : file?.name.includes(".docx")
                                                                                    ? docx
                                                                                    : jpg
                                                                }
                                                                alt=""
                                                                className="w-[45px]"
                                                            />
                                                            <div className="space-y-[2px] overflow-hidden w-[150px]">
                                                                <p className="font-medium text-[14px] truncate">{file?.name}</p>
                                                                <p className="font-normal text-[12px] text-[#333333]">
                                                                    {file.size / 1024 > 1024
                                                                        ? `${(file.size / (1024 * 1024)).toFixed(2)} MB`
                                                                        : `${Math.ceil(file.size / 1024)} KB`}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <button onClick={() => handleDeleteFile(file.name)}>
                                                            <img src={deleteIcon} className="w-[24px]" alt="delete"/>
                                                        </button>
                                                    </div>
                                                ))}
                                        </div>
                                    )
                                    : (
                                        <input
                                            style={{
                                                outline: "none",
                                                border: "1.5px solid #E2E4E9",
                                                boxShadow: "none",
                                            }}
                                            value={newValue}
                                            onChange={(e) => setNewValue(e.target.value)}
                                            id="name"
                                            type={
                                                editModalTitle?.toLowerCase() ===
                                                "email address"
                                                    ? "email"
                                                    : "text"
                                            }
                                            required
                                            placeholder="Enter new info"
                                            className="w-full text border border-[#E2E4E9] rounded-[10px] font-medium text-[14px] pl-3 py-[6px] focus:outline-red-500! mt-1"
                                        />
                                    )}
                    </div>

                    <div className="w-full mt-5 flex justify-end">
                        <button
                            onClick={submitHandler}
                            data-custom="myCustomValue"
                            type="submit"
                            className="bg-[#1A6A73] capitalize text-white ml-auto h-[40px] w-[105px] flex justify-center items-center leading-[17px] rounded-[6px] text-[16px] cursor-pointer"
                        >
                            {isLoading ? <ContactLoader/> : "Save"}
                        </button>
                    </div>
                </form>
            </DialogContent>
        </Dialog>
    );

}


EditModal.propTypes = {
    openModal: PropTypes.bool.isRequired,
    setModal: PropTypes.func.isRequired,
    editModalTitle: PropTypes.string.isRequired,
    editModalDesc: PropTypes.string,
    newValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]).isRequired,
    setNewValue: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    submitHandler: PropTypes.func.isRequired
};

EditModal.defaultProps = {
    editModalDesc: "",
    isLoading: false
};

export default EditModal;