import { useState } from "react";

function usePINValidator() {
  const [isValid, setIsValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  function validatePIN(pin) {
    setErrorMessage("");
    setIsValid(false);

    // Validation rules
    const hasLowercase = /[a-z]/.test(pin);
    const hasUppercase = /[A-Z]/.test(pin);
    const hasDigit = /\d/.test(pin);
    const hasSpecialChar = /[@$!%*?&.]/.test(pin);
    const isValidLength = pin.length >= 8;


    if (!hasLowercase) {
      setErrorMessage("Password must contain at least one lowercase letter.");
      return false;
    }
    if (!hasUppercase) {
      setErrorMessage("Password must contain at least one uppercase letter.");
      return false;
    }
    if (!hasDigit) {
      setErrorMessage("Password must contain at least one digit.");
      return false;
    }
    if (!hasSpecialChar) {
      setErrorMessage("Password must contain at least one special character.");
      return false;
    }
    if (!isValidLength) {
      setErrorMessage("Password must be at least 8 characters long.");
      return false;
    }

    // If all checks pass
    setIsValid(true);
    return true;
  }

  return { isValid, errorMessage, validatePIN };
}

export default usePINValidator;