import React from 'react';
import { useState } from 'react';
import chevron from "../../Images/chevron-right.png";



// eslint-disable-next-line react/prop-types
const BioSection = ({ personalData, handleUpdate}) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const bioLimit = 100;
    const bioText = personalData?.bio || '';

    const truncatedBio = bioText.length > bioLimit ? bioText.substring(0, bioLimit) + '...' : bioText;

    return (
        <div className="w-full flex justify-center items-start gap-[4%]">
            <div className="flex w-[48%] h-fit justify-start flex-col items-start">
                <h3 className="text-[#0A0D14] text-[14px] tablet:text-[15px] leading-5 font-medium">
                    Bio
                </h3>
                <p className="text-[12px] tablet:text-[13px] leading-4 text-[#525866] pt-1">
                    A brief description about yourself
                </p>
            </div>
            <div className="flex w-[48%] h-fit justify-end tablet:justify-start items-end flex-col tablet:items-start">
                <div className="w-fit flex flex-col items-end tablet:items-start">
                    <p className="text-[#0A0D14] capitalize text-[14px] tablet:text-[14px] tracking-wide leading-6">
                        {isExpanded ? bioText : truncatedBio}
                    </p>
                    {bioText.length > bioLimit && (
                        <div className="flex cursor-pointer justify-start items-center gap-[2px] pt-1">
                            <p
                                className="text-[14px] font-small leading-6 text-[#1A6A73] hover:underline"
                                onClick={() => setIsExpanded(!isExpanded)}
                            >
                                {isExpanded ? 'Read Less' : 'Read More'}
                            </p>
                        </div>
                    )}
                </div>
                <div className="flex cursor-pointer justify-start items-center gap-[2px] pt-1" onClick={handleUpdate}>
                    <p className="text-[14px] leading-5 font-medium text-[#1A6A73]">
                        Edit
                    </p>
                    <img src={chevron} className="w-5" alt="edit"/>
                </div>
            </div>
        </div>
    );
};


export default BioSection;