import React, {useEffect, useState} from "react";
import toast from "react-hot-toast";
import {checkOtp} from "@/Api";

const TIMER_DURATION = 20;

// eslint-disable-next-line react/prop-types
const SignupFormOtp = ({setProgress, setStep, otp_check, resendData}) => {
    const [otp, setOtp] = useState("");
    const [timer, setTimer] = useState(TIMER_DURATION);
    const [optCode, setOptCode] = useState(otp_check);

    const [isResendDisabled, setIsResendDisabled] = useState(true);
    const [isResendLoading, setIsResendLoading] = useState(false);

    // Handle OTP input field length
    const handleMaxPinLength = (event) => {
        const newValue = event.target.value;
        if (newValue.length <= 4) {
            setOtp(newValue);
        }
    };

    // Handle OTP validation and step change
    const handleClick = async () => {
        if (String(optCode?.data?.data?.code) === String(otp)) {
            setStep(3);
        } else {
            toast.error("Invalid OTP");
        }
    };

    // Timer logic
    useEffect(() => {
        if (timer > 0 && isResendDisabled) {
            const interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);

            return () => clearInterval(interval);
        }
        if (timer === 0) {
            setIsResendDisabled(false);
        }
    }, [timer, isResendDisabled]);


    // Handle Resend OTP
    const handleResendOtp = async () => {
        if (!isResendDisabled && !isResendLoading) {
            setIsResendLoading(true);
            try {
                const response = await checkOtp(resendData);
                if (response.data.success) {
                    toast.success("OTP has been resent to your email.");
                    setTimer(TIMER_DURATION);
                    setIsResendDisabled(true);
                    setOtp("");
                    setOptCode(response);
                } else {
                    toast.error("Failed to resend OTP.");
                }
            } catch (error) {
                toast.error("Error resending OTP.");
            } finally {
                setIsResendLoading(false);
            }
        }
    };

    useEffect(() => {
        setProgress(2);
    }, [setProgress]);

    return (
        <form onSubmit={(e) => e.preventDefault()} className="z-10">
            <form
                style={{scrollbarWidth: "none", msOverflowStyle: "none"}}
                className="flex flex-col mt-3 items-center shadow-lg h-[100%] bg-white pb-8 rounded-[20px] z-10 tablet:h-fit laptop:h-[70vh] laptop:max-h-[45rem] laptop:min-h-[610px] tablet:w-[60vw] mx-auto tablet:min-w-[520px] tablet:max-w-[522px] max-[767px]:w-[100%] lg:overflow-y-scroll lg:overscroll-none"
            >
                <div className="pt-[8%] laptop:pt-6 text-center flex flex-col items-center border-b-[1px] border-[#E2E4E9] w-[90%] pb-[5%] max-[767px]:w-[100%]">
                    <h1 className="text-[24px] font-bold max-[767px]:text-[20px]">Sign up as a Therapist</h1>
                    <p>Enter your OTP to proceed.</p>
                </div>

                <div className="max-[767px]:w-[90%]">
                    <div className="bg-[#F9F7F3] mt-[3%] w-[450px] text-[13px] py-[3%] px-[5%] rounded-[30px] max-[1130px]:rounded-[20px] max-[767px]:w-[100%] max-[767px]:rounded-[15px]">
                        <p className="text-[15px] font-semibold text-[#191B1E] max-[767px]:text-[14px]">OTP</p>
                        <div className="flex items-center">
                            <input
                                type="number"
                                required
                                value={otp}
                                onChange={handleMaxPinLength}
                                className="bg-[#F9F7F3] text-[15px] font-semibold w-[100%] h-[30px] max-[767px]:text-[14px]"
                                placeholder="Enter Your OTP"
                            />
                        </div>
                    </div>

                    <div className="mt-4">
                        <button
                            type="button"
                            onClick={handleClick}
                            className="bg-[#1A6A73] text-center w-[100%] py-[4%] mt-[5%] text-white rounded-[30px] max-[1130px]:rounded-[20px]"
                        >
                            Continue
                        </button>
                    </div>

                    {/* Resend OTP Section */}
                    <div className="mt-4 text-center">
                        <p className="text-sm text-gray-600">
                            {isResendDisabled ? (
                                <span>Resend OTP in {timer}s</span>
                            ) : (
                                <button
                                    onClick={handleResendOtp}
                                    disabled={isResendLoading}
                                    className={`text-[#1A6A73] ${
                                        isResendLoading ? "opacity-50 cursor-not-allowed" : "cursor-pointer hover:underline"
                                    }`}
                                >
                                    {isResendLoading ? "Resending OTP..." : "Resend OTP"}
                                </button>
                            )}
                        </p>
                    </div>
                </div>
            </form>
        </form>
    );
};

export default SignupFormOtp;